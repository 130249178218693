exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-template-tsx-content-file-path-content-pages-about-mdx": () => import("./../../../src/templates/about-template.tsx?__contentFilePath=/vercel/path0/content/pages/about.mdx" /* webpackChunkName: "component---src-templates-about-template-tsx-content-file-path-content-pages-about-mdx" */),
  "component---src-templates-categories-template-tsx": () => import("./../../../src/templates/categories-template.tsx" /* webpackChunkName: "component---src-templates-categories-template-tsx" */),
  "component---src-templates-category-template-tsx": () => import("./../../../src/templates/category-template.tsx" /* webpackChunkName: "component---src-templates-category-template-tsx" */),
  "component---src-templates-post-template-tsx-content-file-path-content-posts-24-summer-recap-post-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/vercel/path0/content/posts/24-summer-recap/post.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-content-posts-24-summer-recap-post-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-content-posts-figmatinytips-support-crossos-variables-post-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/vercel/path0/content/posts/figmatinytips-support-crossos-variables/post.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-content-posts-figmatinytips-support-crossos-variables-post-mdx" */),
  "component---src-templates-posts-template-tsx": () => import("./../../../src/templates/posts-template.tsx" /* webpackChunkName: "component---src-templates-posts-template-tsx" */),
  "component---src-templates-tag-template-tsx": () => import("./../../../src/templates/tag-template.tsx" /* webpackChunkName: "component---src-templates-tag-template-tsx" */),
  "component---src-templates-tags-template-tsx": () => import("./../../../src/templates/tags-template.tsx" /* webpackChunkName: "component---src-templates-tags-template-tsx" */)
}

